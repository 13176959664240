import gql from 'graphql-tag';
export const GameFields = gql`
  fragment GameFields on Game {
    slug
    title
    restrictAge
    filterIcon262X160 {
      alt
      media {
        title
        url(locale: "en-SG")
      }
    }
    mainThumbnailImg {
      alt
      media {
        title
        url(locale: "en-SG")
      }
    }
    gamesFilterThumbnailImage {
      alt
      media {
        title
        url(locale: "en-SG")
      }
    }
    carouselKeyartGameThumbnailImage {
      alt
      media {
        title
        url(locale: "en-SG")
      }
    }
    comingSoon
    description
    platform
    mastheadBackgroundImage {
      alt
      media {
        url(locale: "en-SG")
        title
      }
      mediaFile {
        title
        description
        fileName
        size
        url
        width
        height
      }
      videoEmbed
    }
    releaseDate(locale: $locale)
    overviewTitle
    audio
    subtitle
    genre
    developer
    bcProductReference
    overviewDescription
    copyRight
    overviewKeyImage {
      ... on ContentTypeAsset {
        caption
        alt
        videoEmbed
        media {
          title
          description
          fileName
          size
          url(locale: "en-SG")
          width
          height
        }
      }
    }
  }
`;
export const BlogListingFields = gql`
  fragment BlogListingFields on BlogListing {
    type
    title
    listItemCollection(locale: $locale, limit: 10) {
      items {
        slug(locale: $locale)
        title(locale: $locale)
        content(locale: $locale) {
          json
          links {
            entries {
              inline {
                sys {
                  id
                }
                ... on ContentTypeAsset {
                  sys {
                    id
                  }
                  media {
                    title
                    url(locale: "en-SG")
                    width
                    height
                  }
                }
              }
            }
            assets {
              block {
                url(locale: "en-SG")
              }
            }
          }
        }
        gameTitle {
          ...GameFields
        }
        primaryTag
        publishDate(locale: $locale)
        shortDescription(locale: $locale)
        mastheadThumbnailImage(locale: $locale) {
          title
          content {
            ... on ContentTypeAsset {
              alt
              media {
                url(locale: "en-SG")
                title
              }
            }
            ... on Game {
              mastheadBackgroundImage {
                media {
                  url(locale: "en-SG")
                }
                videoEmbed
              }
            }
          }
        }
      }
    }
  }
  ${GameFields}
`;
export const BlogFieldsBasic = gql`
  fragment BlogFieldsBasic on Blog {
    title(locale: $locale)
    slug(locale: $locale)
    content(locale: $locale) {
      json
      links {
        entries {
          inline {
            sys {
              id
            }
            ... on ContentTypeAsset {
              sys {
                id
              }
              media {
                title
                url(locale: "en-SG")
                width
                height
              }
            }
          }
          block {
            sys {
              id
            }
            ... on HyperlinkComponent {
              sys {
                id
              }
              title
              description
              target
              url
              thumbnail {
                media {
                  url(locale: "en-SG")
                }
              }
            }
            ... on ImageInARow {
              sys {
                id
              }
              title
              hideDownloadButton
              isLandscapeImage
              typeShowImage
              marginDesktop
              marginMobile
              imagesCollection(limit: 10, locale: $locale) {
                items {
                  alt
                  media {
                    contentType
                    url(locale: "en-SG")
                    width
                    height
                  }
                  url
                  videoEmbed
                }
              }
            }
            ... on Quote {
              content(locale: $locale) {
                json
              }
              author
            }
          }
        }
        assets {
          block {
            url
          }
        }
      }
    }
    gameTitle {
      ...GameFields
    }
    primaryTag
    publishDate(locale: $locale)
    shortDescription(locale: $locale)
    mastheadThumbnailImage(locale: $locale) {
      title
      content {
        ... on ContentTypeAsset {
          alt
          media {
            url(locale: "en-SG")
            title
          }
        }
        ... on Game {
          mastheadBackgroundImage {
            media {
              url(locale: "en-SG")
            }
            videoEmbed
          }
        }
      }
    }
    keywords(locale: $locale)
    author(locale: $locale) {
      game
      role
      designation
      profilePicture {
        media {
          url(locale: "en-SG")
        }
      }
    }
  }
  ${GameFields}
`;
export const GameGroupFields = gql`
  fragment GameGroupFields on GameGroup {
    title
    type
    seeAllText
    seeAllSlug
    openNewTab
  }
`;
export const AssetFields = gql`
  fragment AssetFields on ContentTypeAsset {
    internalName
    caption
    alt
    media(locale: $locale) {
      title
      description
      fileName
      size
      url(locale: "en-SG")
      width
      height
    }
    url
    videoEmbed
    __typename
  }
`;
export const GamesListingFields = gql`
  fragment GamesListingFields on GamesListing {
    seeAllText(locale: $locale)
    seeAllSlug(locale: $locale)
    openNewTab(locale: $locale)
    type
    description
    title
    stepScroll
    dataSource
    queryValue
    ticker {
      title
      url(locale: "en-SG")
    }
    listItemCollection(locale: $locale) {
      items {
        slug
        title
        filterIcon262X160 {
          alt
          media {
            title
            url(locale: "en-SG")
          }
        }
        mastheadGalleryCollection(limit: 15) {
          items {
            ...AssetFields
          }
        }
        mainThumbnailImg {
          alt
          media {
            title
            url(locale: "en-SG")
          }
        }
        ...GameFields
      }
    }
  }
  ${AssetFields}
  ${GameFields}
`;
export const GlobalFields = gql`
  fragment GlobalFields on Global {
    type
    configuration(locale: $locale)
  }
`;
export const KeyFeaturesFields = gql`
  fragment KeyFeaturesFields on KeyFeature {
    internalName
    title
    featuredImage {
      internalName
      caption
      alt
      media(locale: "en-SG") {
        title
        description
        fileName
        contentType
        size
        url(locale: "en-SG")
        width
        height
      }
      videoEmbed
    }
    imagePosition
    imageFrameType
  }
`;
export const MediaGalleryFields = gql`
  fragment MediaGalleryFields on MediaGallery {
    __typename
    internalName
    isAaAgame
    title
  }
`;
export const NewsListingFields = gql`
  fragment NewsListingFields on NewsListing {
    title(locale: $locale)
    seeAllText(locale: $locale)
    seeAllSlug(locale: $locale)
    openNewTab(locale: $locale)
    type
    dataSource
    stepScroll
    listItemCollection(locale: $locale, limit: 8) {
      items {
        title(locale: $locale)
        fromDate(locale: $locale)
        toDate(locale: $locale)
        publishDate(locale: $locale)
        listInListingPage(locale: $locale)
        slug(locale: $locale)
        noDateText(locale: $locale)
        game {
          ...GameFields
        }
        shortDescription(locale: $locale)
        customTags(locale: "en-SG")
        secondaryTags(locale: $locale)
        mainImage(locale: $locale) {
          alt
          media {
            url(locale: "en-SG")
            title
          }
        }
        content(locale: $locale) {
          json
          links {
            entries {
              inline {
                sys {
                  id
                }
                ... on ContentTypeAsset {
                  sys {
                    id
                  }
                  media {
                    title
                    url(locale: "en-SG")
                    width
                    height
                  }
                }
              }
            }
            assets {
              block {
                url
              }
            }
          }
        }
      }
    }
  }
  ${GameFields}
`;
export const NewsFieldsBasic = gql`
  fragment NewsFieldsBasic on News {
    title(locale: $locale)
    fromDate
    toDate
    publishDate
    hideMasthead
    listInListingPage
    noDateText(locale: $locale)
    slug(locale: $locale)
    shortDescription(locale: $locale)
    game {
      ...GameFields
    }
    customTags(locale: "en-SG")
    secondaryTags
    mainImage {
      alt
      media {
        url(locale: "en-SG")
        title
      }
      videoEmbed(locale: $locale)
    }
    content(locale: $locale) {
      json
    }
  }
  ${GameFields}
`;
export const CharactersFields = gql`
  fragment CharactersFields on Characters {
    title
    description {
      json
    }
    additionalDescription {
      json
    }
    characterImage {
      ...AssetFields
    }
  }
  ${AssetFields}
`;
export const NewsFields = gql`
  fragment NewsFields on News {
    title(locale: $locale)
    fromDate
    toDate
    publishDate
    hideMasthead
    listInListingPage
    fromDate
    noDateText(locale: $locale)
    slug(locale: $locale)
    shortDescription(locale: $locale)
    game(locale: $locale) {
      title
      platform
      slug
      bcProductReference
      gamesFilterThumbnailImage {
        alt
        media {
          title
          url(locale: "en-SG")
        }
      }
      mainThumbnailImg {
        alt
        media {
          title
          url(locale: "en-SG")
        }
      }
    }
    customTags(locale: "en-SG")
    secondaryTags
    mainImage(locale: $locale) {
      alt
      media {
        url(locale: "en-SG")
        title
      }
      videoEmbed(locale: $locale)
    }
    uiContent(locale: $locale)
    content(locale: $locale) {
      json
      links {
        entries {
          inline {
            ... on ContentTypeAsset {
              sys {
                id
              }
              media {
                title
                url(locale: "en-SG")
              }
            }
          }
          block {
            sys {
              id
            }
            ... on ImageInARow {
              sys {
                id
              }
              title
              hideDownloadButton
              isLandscapeImage
              typeShowImage
              marginDesktop
              marginMobile
              imagesCollection(limit: 10) {
                items {
                  alt
                  caption
                  media {
                    contentType
                    url(locale: "en-SG")
                    width
                    height
                  }
                  url
                  videoEmbed
                }
              }
            }
            ... on ImageAutoSize {
              sys {
                id
              }
              title
              imagesCollection(limit: 10) {
                items {
                  alt
                  caption
                  media {
                    contentType
                    url(locale: "en-SG")
                    width
                    height
                  }
                  url
                  videoEmbed
                }
              }
            }
            ... on Button {
              sys {
                id
              }
              title(locale: $locale)
              target(locale: $locale)
              label(locale: $locale)
              url(locale: $locale)
              backgroundColor(locale: $locale)
            }
            ... on ContentTypeAsset {
              alt
              caption
              media {
                contentType
                url(locale: "en-SG")
                width
                height
              }
              marginTop
              marginBottom
              videoEmbed
            }
            ... on Quote {
              content(locale: $locale) {
                json
              }
              author
            }
            ... on GameCharacterList {
              title
              internalName
              listOfCharactersCollection(limit: 10) {
                items {
                  title
                  additionalDescription {
                    json
                  }
                  characterImage {
                    media(locale: $locale) {
                      url(locale: "en-SG")
                    }
                    url
                  }
                }
              }
            }
            ... on HyperlinkComponent {
              title
              description
              target
              url
              thumbnail {
                media {
                  url(locale: "en-SG")
                }
              }
            }
            ... on NewsGoogleForm {
              title
              iframeContent
            }
          }
        }
        assets {
          block {
            sys {
              id
            }
            title(locale: $locale)
            description(locale: $locale)
            url(locale: "en-SG")
            contentType
            width
            height
          }
        }
      }
    }
    isProtectedPassword
    shopNowUrl
    passwordDescription(locale: $locale) {
      json
      links {
        entries {
          hyperlink {
            sys {
              id
            }
          }
          inline {
            sys {
              id
            }
          }
          block {
            sys {
              id
            }
            ... on ContentTypeAsset {
              alt
              media {
                contentType
                url(locale: "en-SG")
                width
                height
              }
              videoEmbed
            }
          }
        }
        assets {
          block {
            sys {
              id
            }
            title(locale: $locale)
            description(locale: $locale)
            url(locale: "en-SG")
            width
            height
          }
        }
      }
    }
    idNew: password(locale: $locale)
  }
`;
export const PlatformListingFields = gql`
  fragment PlatformListingFields on PlatformListing {
    title(locale: $locale)
    platform(locale: $locale)
  }
`;
export const SeoStandardFields = gql`
  fragment SeoStandardFields on SeoStandard {
    seoTitle(locale: $locale)
    seoDescription(locale: $locale)
    seoRobots(locale: $locale)
    ogTitle(locale: $locale)
    ogDescription(locale: $locale)
    seoKeywords(locale: $locale)
    seoAuthor(locale: $locale)
    seoPublisher(locale: $locale)
  }
`;
export const TagsParentFields = gql`
  fragment TagsParentFields on Tags {
    tagName(locale: $locale)
    backgroundColor(locale: $locale)
    tagNameFilter(locale: $locale)
  }
`;
export const TagsFields = gql`
  fragment TagsFields on Tags {
    tagName(locale: $locale)
    backgroundColor(locale: $locale)
    tagNameFilter(locale: $locale)
    parentsCollection(limit: 4, locale: $locale) {
      items {
        tagName(locale: $locale)
        tagNameFilter(locale: $locale)
        backgroundColor(locale: $locale)
      }
    }
  }
`;
export const GetAssetsByFileNameDocument = gql`
  query GetAssetsByFileName($fileName: String!, $preview: Boolean!, $locale: String = "en-SG") {
    assetCollection(where: {fileName: $fileName}, limit: 1, locale: $locale, preview: $preview) {
      items {
        url(locale: "en-SG")
        size
        title
        description
        contentType
        width
        height
      }
    }
  }
`;
export const GetBlogListingByIdQueryDocument = gql`
  query GetBlogListingByIdQuery($id: String!, $locale: String) {
    blogListing(id: $id, locale: $locale) {
      ...BlogListingFields
    }
  }
  ${BlogListingFields}
`;
export const GetBlogArticlesDocument = gql`
  query GetBlogArticles($slug: String!, $preview: Boolean!, $locale: String = "en-SG") {
    blogCollection(where: {slug: $slug}, limit: 1, locale: $locale, preview: $preview) {
      total
      skip
      limit
      items {
        ...BlogFieldsBasic
      }
    }
  }
  ${BlogFieldsBasic}
`;
export const GetListblogDocument = gql`
  query GetListblog(
    $skip: Int = 0
    $limit: Int = 10
    $where: BlogFilter
    $order: [BlogOrder] = [publishDate_DESC]
    $locale: String
  ) {
    blogCollection(where: $where, skip: $skip, limit: $limit, order: $order, locale: $locale) {
      total
      skip
      limit
      items {
        title(locale: $locale)
        slug(locale: $locale)
        primaryTag(locale: $locale)
        gameTitle(locale: $locale) {
          ...GameFields
        }
        publishDate(locale: $locale)
        shortDescription(locale: $locale)
        mastheadThumbnailImage(locale: $locale) {
          title
          content {
            ... on ContentTypeAsset {
              alt
              media {
                url(locale: "en-SG")
                title
              }
            }
            ... on Game {
              mastheadBackgroundImage {
                media {
                  url(locale: "en-SG")
                }
                videoEmbed
              }
            }
          }
        }
      }
    }
  }
  ${GameFields}
`;
export const GetFilterByDocument = gql`
  query GetFilterBy($locale: String, $where: FilterByFilter, $skip: Int = 0, $limit: Int = 100) {
    filterByCollection(locale: $locale, where: $where, skip: $skip, limit: $limit) {
      items {
        title
        keyFilterForBg
        type {
          title
        }
        typeFilter
        keyFilter
      }
    }
  }
`;
export const GetGamesForEStoreProductDetailDocument = gql`
  query GetGamesForEStoreProductDetail($locale: String, $sku: String) {
    gameCollection(
      where: {AND: [{bcProductReference: $sku}, {languagesPage_not_in: ["Thai", "Indo"]}]}
      locale: $locale
      limit: 1
    ) {
      items {
        title
        description
        overviewTitle
        mastheadBackgroundImage {
          media {
            url(locale: "en-SG")
          }
        }
        restrictAge
        mastheadGalleryAboutGameCollection {
          items {
            media {
              url(locale: "en-SG")
            }
          }
        }
        overviewDescription
        overviewKeyImage {
          ... on ContentTypeAsset {
            caption
            alt
            videoEmbed
            media {
              title
              description
              fileName
              size
              url(locale: "en-SG")
              width
              height
            }
          }
        }
        releaseDate
        developer
        platform
        genre
        audio
        subtitle
        compareEditionSection {
          internalName
          configuration
        }
      }
    }
  }
`;
export const GetGamesQueryDetailOtherGameDocument = gql`
  query GetGamesQueryDetailOtherGame($locale: String, $slug: String, $preview: Boolean) {
    gameCollection(where: {slug: $slug}, limit: 1, locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
        otherLanguagesCollection(limit: 4) {
          items {
            slug(locale: "en-SG")
            languagesPage
          }
        }
        languagesPage
      }
    }
  }
`;
export const GetGamesQueryDetailDocument = gql`
  query GetGamesQueryDetail($locale: String, $slug: String, $preview: Boolean) {
    gameCollection(where: {slug: $slug}, limit: 1, locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
        ...GameFields
        mastheadGalleryCollection(limit: 10) {
          items {
            ...AssetFields
          }
        }
        sectionCollection(limit: 8) {
          items {
            noPadding
            sectionWrapperStyles
            contentWrapperStyles
            internalName
            theme {
              name
              codeId
            }
            sectionId
            blocksCollection(locale: $locale, limit: 1) {
              items {
                ... on MediaGallery {
                  __typename
                  internalName
                  isAaAgame
                  title
                  screenshotsCollection(limit: 20) {
                    items {
                      ...AssetFields
                    }
                  }
                  videoTrailersCollection(limit: 10) {
                    items {
                      ...AssetFields
                    }
                  }
                }
                ... on GamesListing {
                  title
                  type
                  seeAllText
                  seeAllSlug
                  openNewTab
                  listItemCollection(limit: 10) {
                    items {
                      ...GameFields
                    }
                  }
                }
                ... on GameKeyFeatures {
                  internalName
                  title
                  isAaaGame
                  keyFeatureContainerCollection(limit: 5) {
                    items {
                      ... on GameKeyFeaturesKeyFeatureContainerItem {
                        ... on KeyFeatureMultipleImagesWithDescription {
                          internalName
                          title
                          description
                          mainFeaturedImage {
                            ...AssetFields
                          }
                          leftColumnFeaturedImagesCollection(limit: 6) {
                            items {
                              ...AssetFields
                            }
                          }
                          rightColumnFeaturedImagesCollection(limit: 6) {
                            items {
                              ...AssetFields
                            }
                          }
                        }
                        ... on KeyFeature {
                          internalName
                          title
                          description
                          featuredImage {
                            ...AssetFields
                          }
                          imagePosition
                          imageFrameType
                        }
                      }
                    }
                  }
                }
                ... on GameCharacterList {
                  title
                  internalName
                  listOfCharactersCollection(limit: 60) {
                    items {
                      ...CharactersFields
                    }
                  }
                }
                ... on GameGroup {
                  ...GameGroupFields
                  listGamesCollection(limit: 10) {
                    items {
                      ...GameFields
                    }
                  }
                }
                ... on GameForCollectors {
                  title
                  collectionsCollection(limit: 5) {
                    items {
                      title
                      featuredCollectionImage {
                        ...AssetFields
                      }
                      description {
                        json
                      }
                      ctaLink
                      labelCtaLink
                    }
                  }
                }
              }
            }
          }
        }
        gameEditionsCollection(limit: 10) {
          items {
            title
            bulletPoints
            eStoreGameTitle
            tagsCollection(limit: 5) {
              items {
                ...TagsFields
              }
            }
          }
        }
      }
    }
  }
  ${GameFields}
  ${AssetFields}
  ${CharactersFields}
  ${GameGroupFields}
  ${TagsFields}
`;
export const GetListGameBrandDocument = gql`
  query GetListGameBrand($locale: String, $skip: Int, $limit: Int, $where: GameBrandFilter) {
    gameBrandCollection(locale: $locale, skip: $skip, limit: $limit, where: $where) {
      total
      skip
      items {
        title(locale: $locale)
        idForBig(locale: $locale)
      }
    }
  }
`;
export const GetListTitleGamesDocument = gql`
  query GetListTitleGames(
    $locale: String
    $skip: Int
    $limit: Int
    $where: GameFilter
    $order: [GameOrder] = [sys_publishedAt_DESC]
  ) {
    gameCollection(locale: $locale, skip: $skip, limit: $limit, where: $where, order: $order) {
      total
      skip
      items {
        title
      }
    }
  }
`;
export const GetListGamesDocument = gql`
  query GetListGames(
    $locale: String
    $skip: Int
    $limit: Int
    $where: GameFilter
    $order: [GameOrder]
    $preview: Boolean
  ) {
    gameCollection(
      locale: $locale
      skip: $skip
      limit: $limit
      where: $where
      order: $order
      preview: $preview
    ) {
      total
      skip
      limit
      items {
        title
        description
        platform
        genre
        slug
        filterIcon262X160 {
          alt
          media {
            url(locale: "en-SG")
          }
        }
        mainThumbnailImg {
          alt
          media {
            title
            url(locale: "en-SG")
          }
        }
        gamesFilterThumbnailImage {
          alt
          media {
            title
            url(locale: "en-SG")
          }
        }
        mastheadBackgroundImage {
          internalName
          alt
          media {
            url(locale: "en-SG")
          }
          mediaFile {
            title
            description
            fileName
            size
            url
            width
            height
          }
        }
        mastheadGalleryCollection {
          items {
            alt
          }
        }
        releaseDate
        overviewTitle
        audio
        subtitle
        bcProductReference
      }
    }
  }
`;
export const GetGamesListingByIdQueryDocument = gql`
  query GetGamesListingByIdQuery($id: String!, $locale: String) {
    gamesListing(id: $id, locale: $locale) {
      ...GamesListingFields
    }
  }
  ${GamesListingFields}
`;
export const GetGlobalByTypeDocument = gql`
  query GetGlobalByType($skip: Int = 0, $limit: Int = 1, $type: String) {
    globalCollection(skip: $skip, limit: $limit, where: {AND: [{type: $type}]}) {
      items {
        configuration
      }
    }
  }
`;
export const GetOurPickMenuDocument = gql`
  query GetOurPickMenu($locale: String, $id: String!) {
    gamesListing(id: $id, locale: $locale) {
      queryValue
      listItemCollection(limit: 5) {
        items {
          title
          slug
          mastheadBackgroundImage {
            media {
              url(locale: "en-SG")
            }
          }
          mainThumbnailImg {
            alt
            media {
              title
              url(locale: "en-SG")
            }
          }
          gamesFilterThumbnailImage {
            alt
            media {
              title
              url(locale: "en-SG")
            }
          }
        }
      }
    }
  }
`;
export const GetGlobalDocument = gql`
  query GetGlobal($id: String!, $locale: String) {
    global(id: $id, locale: $locale) {
      ...GlobalFields
    }
  }
  ${GlobalFields}
`;
export const GetExploreNewUniverseDocument = gql`
  query GetExploreNewUniverse($id: String!, $locale: String) {
    exploreNewUniverse(id: $id, locale: $locale, preview: false) {
      title
      mashtHead {
        title
        content {
          ... on Game {
            title
            slug
            description
            overviewTitle
            bcProductReference
            mastheadBackgroundImage {
              media {
                url(locale: "en-SG")
              }
              videoEmbed
            }
            mainThumbnailImg {
              alt
              media {
                title
                url(locale: "en-SG")
              }
            }
            mastheadGalleryCollection {
              items {
                caption
                videoEmbed
              }
            }
            platform
          }
        }
      }
      brandListCollection(limit: 4) {
        items {
          title
          videoId
          mediaVideo {
            title
            description
            fileName
            size
            url
            width
            height
          }
          mediaImage {
            title
            description
            fileName
            size
            url(locale: "en-SG")
            width
            height
          }
          gameListCollection(limit: 4) {
            items {
              title
              listItemCollection(limit: 4) {
                items {
                  title
                  slug
                  mastheadBackgroundImage {
                    media {
                      url(locale: "en-SG")
                    }
                  }
                  mainThumbnailImg {
                    media {
                      url(locale: "en-SG")
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GetGameMediaGalleryDocument = gql`
  query GetGameMediaGallery($id: String!, $locale: String) {
    mediaGallery(id: $id, locale: $locale) {
      title
      screenshotsCollection {
        items {
          media {
            url(locale: "en-SG")
          }
        }
      }
    }
  }
`;
export const GetMasTheadCollectionDocument = gql`
  query GetMasTheadCollection($id: String!, $locale: String) {
    mashthead(id: $id, locale: $locale) {
      title
      content {
        ... on ContentTypeAsset {
          caption
          alt
          videoEmbed
          media {
            title
            description
            fileName
            size
            url(locale: "en-SG")
            width
            height
          }
          mediaFile {
            title
            description
            fileName
            size
            url
            width
            height
          }
        }
        ... on Game {
          title
          slug
          description
          overviewTitle
          bcProductReference
          mastheadBackgroundImage {
            media {
              url(locale: "en-SG")
            }
            mediaFile {
              title
              description
              fileName
              size
              url
              width
              height
            }
            videoEmbed
          }
          mastheadGalleryCollection(limit: 10) {
            items {
              ...AssetFields
            }
          }
          platform
        }
      }
    }
  }
  ${AssetFields}
`;
export const GetSuggestionCardDocument = gql`
  query GetSuggestionCard($id: String!, $locale: String) {
    suggestionCard(id: $id, locale: $locale) {
      title
      description
      thumbnail {
        url(locale: "en-SG")
        title
      }
      url
      titleUrl
    }
  }
`;
export const GetNavigationPopularDocument = gql`
  query GetNavigationPopular($id: String!, $locale: String) {
    navigationPopular(id: $id, locale: $locale) {
      platform
      price
      genre
    }
  }
`;
export const GetListNewDocument = gql`
  query GetListNew(
    $skip: Int = 0
    $limit: Int = 10
    $where: NewsFilter
    $order: [NewsOrder] = [publishDate_DESC, sys_publishedAt_DESC]
    $locale: String
  ) {
    newsCollection(where: $where, skip: $skip, limit: $limit, order: $order, locale: $locale) {
      total
      skip
      limit
      items {
        ...NewsFieldsBasic
      }
    }
  }
  ${NewsFieldsBasic}
`;
export const GetNewsArticlesDocument = gql`
  query GetNewsArticles($slug: String!, $preview: Boolean!, $locale: String = "en-SG") {
    newsCollection(where: {slug: $slug}, limit: 1, locale: $locale, preview: $preview) {
      total
      skip
      limit
      items {
        sys {
          id
        }
        ...NewsFields
      }
    }
  }
  ${NewsFields}
`;
export const GetNewsListingByIdQueryDocument = gql`
  query GetNewsListingByIdQuery($id: String!, $locale: String) {
    newsListing(id: $id, locale: $locale) {
      ...NewsListingFields
    }
  }
  ${NewsListingFields}
`;
export const GetPageBySlugDocument = gql`
  query GetPageBySlug($slug: String!, $preview: Boolean!, $locale: String) {
    pageCollection(where: {slug: $slug}, limit: 1, preview: $preview, locale: $locale) {
      items {
        slug
        title(locale: $locale)
        seoStandard {
          ...SeoStandardFields
        }
        theme {
          name
          codeId
        }
        navibar {
          type
          configuration
        }
        footer {
          type
          configuration
        }
        sectionCollection {
          items {
            ... on Entry {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
  ${SeoStandardFields}
`;
export const GetPlatformListingByIdQueryDocument = gql`
  query GetPlatformListingByIdQuery($id: String!, $locale: String) {
    platformListing(id: $id, locale: $locale) {
      ...PlatformListingFields
    }
  }
  ${PlatformListingFields}
`;
export const GetSaleListingByIdQueryDocument = gql`
  query GetSaleListingByIdQuery($id: String!, $locale: String) {
    saleEvent(id: $id, locale: $locale, preview: false) {
      title
      image {
        media {
          url(locale: "en-SG")
        }
      }
      nameEventForBig
      description
      startTime
      endTime
      ctaLink
      ctaLabel
    }
  }
`;
export const GetSectionByIdQueryDocument = gql`
  query GetSectionByIdQuery($id: String!, $locale: String) {
    section(id: $id, locale: $locale) {
      sys {
        id
      }
      noPadding
      sectionWrapperStyles
      contentWrapperStyles
      internalName
      sectionId
      theme {
        name
        codeId
      }
      blocksCollection(locale: $locale) {
        items {
          ... on Entry {
            sys {
              id
            }
          }
        }
      }
    }
  }
`;
export const GetSortByDocument = gql`
  query GetSortBy($locale: String) {
    sortByCollection(locale: $locale) {
      items {
        title
      }
    }
  }
`;
export const GetSupportFaqDocument = gql`
  query GetSupportFAQ($id: String!, $locale: String) {
    supportFaq(id: $id, locale: $locale) {
      internalName
      questionListingCollection(limit: 100) {
        items {
          key
          questionsCollection(limit: 100) {
            items {
              label
              content {
                json
              }
            }
          }
        }
      }
    }
  }
`;
